import axios, { AxiosResponse } from "axios";
import { IUser } from "../types/IUser";
import { apiUrl } from "./API";

const API = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: () => true,
});

export const authenticate = (
  username: string,
  password: string
): Promise<AxiosResponse | void> => {
  const data = {
    username: username,
    password: password,
    module: "2",
  };
  return API.post("Login/Login", data)
    .then((response: AxiosResponse<IUser>) => {
      return response;
    })
    .catch(handleError);
};

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}

// export const authenticate = (
//   id: string,
//   password: string
// ): Promise<IUser | void> => {
//   const login = {
//     username: id,
//     password: password,
//   };

//   return API.post("login", login)
//     .then((response: AxiosResponse<IUser>) => {
//       return response.data;
//     })
//     .catch(function (error: any) {
//       console.log("error from login api :  " + error);
//       return;
//     });
// };
